import React from 'react'
import { Box, Button, TextInput, Layer, Text, Form, FormField } from 'grommet';

class JoinFamilyForm extends React.Component{
    constructor(props){
        super()
        this.state={
            fields:{
                familycode:"",
                familyname:""
            },
            errors:{}
        }
        this.handleInputChange=this.handleInputChange.bind(this)
        this.toggleJoinFamilyForm=props.toggle
        this.toggleJoinFamilyForm=this.toggleJoinFamilyForm.bind(this)
        this.joinFamily=props.joinFamily
        this.joinFamily=this.joinFamily.bind(this)
    }

    postFamilyForm(){
        console.log("post")
        if (this.handleValidation()){
            this.joinFamily(this.state.fields.familycode, this.state.fields.familyname)
            this.props.toggle()
        } else{

        }
    }

    handleValidation() {
        let formIsValid = true;
        let errors = {};

        // Validate family name
        if (!this.state.fields.familyname) {
            formIsValid = false;
            errors["familyname"] = "Veuillez renseigner un nom de famille";
        } else if (this.state.fields.familyname.length > 255) {
            formIsValid = false;
            errors["familyname"] = "Le nom de famille n'est pas valide";
        }

        // Validate family code
        if (!this.state.fields.familycode) {
            formIsValid = false;
            errors["familycode"] = "Veuillez renseigner un code";
        } else if (this.state.fields.familycode.length > 8) {
            formIsValid = false;
            errors["familycode"] = "Le code n'est pas valide";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleInputChange(event){
        const eventtargetvalue = event.target.value
        const name = event.target.name
        this.setState(prevState => {
            return {fields: {...prevState.fields, [name]: eventtargetvalue}}
        })
    }

    render(){
        const { open } = this.props;
        return (
            <Layer onEsc={this.props.toggle} onClickOutside={this.props.toggle}>
                <Box pad="medium" gap="small">
                    <Text weight="bold">Rejoindre une famille existante</Text>
                    <Box>
                        <Text>Renseigner le code d'invitation <span role="img" aria-label="cadenas">🔒</span></Text>
                        <Text>Pour rejoindre la famille, renseigner le nom de la famille et le code d'invitation fournis par la personne qui vous invite, puis valider.</Text>
                    </Box>

                    <Box>
                        <Form>
                            <FormField margin={{ bottom: 'small' }} error={this.state.errors["familyname"]}>
                                <TextInput 
                                    name="familyname" 
                                    placeholder="Nom de la famille" 
                                    value={this.state.fields.familyname} 
                                    onChange={this.handleInputChange} 
                                />
                            </FormField>
                            <FormField margin={{ bottom: 'small' }} error={this.state.errors["familycode"]}>
                                <TextInput 
                                    name="familycode" 
                                    placeholder="Code à 6 lettres" 
                                    value={this.state.fields.familycode} 
                                    onChange={this.handleInputChange} 
                                />
                            </FormField>
                            <Box direction="row" justify="end" margin={{ top: 'small' }}>
                                <Button label="Annuler" onClick={this.props.toggle} />
                                <Button primary label="Valider" onClick={() => { this.postFamilyForm() }}/>
                            </Box>
                        </Form>
                    </Box>
                </Box>
            </Layer>
        );
    };
}

export default JoinFamilyForm