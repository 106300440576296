import React, { useState, useEffect } from 'react';
import Wish3 from './Wish3';
import WishForm from './WishForm';
import Appalert from './Appalert';
import { Anchor, Box, Button, Grid, Heading, Page, PageContent, PageHeader, Spinner, Text, Select } from 'grommet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import userservice from './services/userservice';
import Placeholder from './Placeholder';
import { useContext } from 'react';
import { ThemeContext } from './App';
import { Ascending, Descending } from 'grommet-icons';

const StyledHeading = styled(Heading)`
        color: ${props => props.light==false?"#3D6373":"#C7614D"};
        text-decoration: none !important;
        &:hover {
          text-decoration: none;
          color: ${props => props.light==true?"#3D6373":"#C7614D"};
        }
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
        &:hover {
        text-decoration: none;
        color: ${props => props.light==true?"#3D6373":"#C7614D"};
        }
`;

const Whishlist = (props) => {
  const theme = useContext(ThemeContext);
  const [wishes, setWishes] = useState([]);
  const [username, setUsername] = useState("");
  const [alert, setAlert] = useState("");
  const [showEditor, setShowEditor] = React.useState(false);
  const [wishToEdit, setWishToEdit] = React.useState(false);
  const [wishlist, setWishlist] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showNewWish, setShowNewWish] = useState(false);
  const [title, setTitle] = useState("");
  const [sortingMethod, setSortingMethod] = useState(null);

  const updateAlert = (message, status)=>{
    console.log("setting alert", message)
      setAlert(previousState => {
      return { ...previousState, message: message, status:status }
    });
  }
  const owner = props.owner;
  const self = props.owner === "me";

  useEffect(() => {
    fetch('/api/users/' + owner + '/wishlists')
      .then(res => {
        if (res.ok) {
          return res.json();
        } else if (res.status == 401) {
          props.updateUser("")
          userservice.signout()
          throw res
        }
      })
      .then(responseJson => {
        setWishes(responseJson[0].Wishes);
        setWishlist(responseJson[0]);
        const userName = responseJson[0].User.name;
        setUsername(userName);
        setTitle(self ? "Ma liste de cadeaux" : "Liste de " + userName);
        setIsLoading(false);
      })
      .catch(error => {
        console.log("error ", error)
      }
      );
  }, [props.owner, self]);

  const toggle = () => {
    setShowNewWish(!showNewWish);
  };

  function toggleEdit(){
    setShowEditor(!showEditor);
  }

  const getWishDetails = async (url) => {
    const q = "/api/wishes/findURL";
    try {
      const response = await fetch(q, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  const postwish = (newwish) => {
    const q = '/api/wishlists/' + wishlist.uid + '/wishes';
    const { name, importance, comment, url, img, isPurchased, isSecret, purchaseDate, buyerUid } = newwish;
    fetch(q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, importance, comment, url, img, isPurchased, isSecret, purchaseDate, buyerUid }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        updateAlert("Cadeau ajouté", "normal");
        setWishes([...wishes, data]);
      })
      .catch(err => {updateAlert(err.message, "normal"); console.log(err)});
  };

  const putwish = (newwish, wishId) => {
    const { name, importance, comment, url, img, isPurchased, isSecret, purchaseDate, buyerUid } = newwish;
    fetch('/api/wishes/' + wishId, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, importance, comment, url, img, isPurchased, isSecret, purchaseDate, buyerUid }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        updateAlert("Cadeau modifié", "normal");
        setWishes(wishes.map(wish => wish.uid === wishId ? data : wish));
      })
      .catch(err => updateAlert(err.message, "critical"));
  };

  const deletewish = (wishId) => {
    setIsLoading(true);
    fetch('/api/wishes/' + wishId, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(() => {
        updateAlert("Cadeau supprimé", "normal");
        setWishes(wishes.filter(wish => wish.uid !== wishId));
        setIsLoading(false);
      })
      .catch(err => updateAlert(err.message, "critical"));
  };

  const handleSortChange = ({ option }) => {
    const [criteria, order] = option.value.split('-');
    setSortingMethod({ criteria, order });
  };

  const sortedWishes = wishes.sort((item1, item2) => {
    if (!sortingMethod) {
      return (item2.importance - item1.importance) || item1.name.localeCompare(item2.name);
    }
    
    let comparison = 0;
    if (sortingMethod.criteria === "importance") {
      comparison = item1.importance - item2.importance || item2.name.localeCompare(item1.name);
    } else {
      comparison = item1.name.localeCompare(item2.name);
    }

    return sortingMethod.order === "ascending" ? comparison : -comparison;
  });

  const wishArray = sortedWishes.map(item => (
    <Wish3
      putwish={putwish}
      deletewish={deletewish}
      key={item.uid}
      name={item.name}
      importance={item.importance}
      isSecret={item.isSecret}
      isPurchased={item.isPurchased}
      purchaseDate={item.purchaseDate}
      purchasedBy={item.buyerUid}
      self={self}
      comment={item.comment}
      img={item.img}
      createdAt={item.createdAt}
      url={item.url}
      id={item.uid}
      buyer={item.buyer?.name}
      showEditor={showEditor}
      setShowEditor={setShowEditor}
      setWishToEdit={setWishToEdit}
      recipient={username}
      creator={item.creator?.name}
    />
  ));

  return (
    <Page>
      {showNewWish &&
        <WishForm owner={owner} wish={{isSecret: self===false}} getWishDetails={getWishDetails} toggle={toggle} postwish={postwish} self={self} />
      }
      {showEditor && 
        <WishForm 
            editForm 
            owner={owner}
            deletewish={deletewish} 
            putwish={putwish} 
            getWishDetails={getWishDetails} 
            toggle={toggleEdit} 
            open={showEditor}
            self={self}
            wish={wishToEdit}
            setShowEditor={setShowEditor}
        >
        </WishForm>
      }
      {!showNewWish && !showEditor &&
      <>
        <PageHeader
          responsive
          actions={
            <Button
              primary
              onClick={toggle}
              label={`${self === false ? '👻' : ''} Ajouter un cadeau ${self === false ? 'secret' : ''}`}
            />
          }
          parent={<Anchor as={()=><StyledLink to="/app/families/"><StyledHeading level="4" light={theme==true}>Familles</StyledHeading></StyledLink>} light={theme==true} label="Familles" preventScrollReset={true}/>}
          title={title}
        />
        <PageContent width={{min:"small", max:"xxlarge"}}>
          {isLoading && (
            <Box align="center" justify="center" gap="medium" margin={{ vertical: "large" }}>
              <Spinner size="large" />
              <Text>Chargement des cadeaux...</Text>
            </Box>
          )}
          {alert.message&&
              <Appalert setAlert={updateAlert} alert={alert}></Appalert>
          }
          {!isLoading && (<Box 
            width="xsmall" 
            margin={{ bottom: "small" }} 
            alignSelf="end"
          >
            <Select
                id="sortingMethod"
                name="sortingMethod"
                value={sortingMethod ? {
                  label: <Box direction="row" align="center" gap="small">
                    {sortingMethod.criteria}
                    {sortingMethod.order === "ascending" ? <Ascending size="small"/> : <Descending size="small"/>}
                  </Box>,
                  value: `${sortingMethod.criteria}-${sortingMethod.order}`
                } : undefined}
                onChange={handleSortChange}
                options={[
                  { label: <Box size="xxsmall" direction="row" align="center" pad="small" gap="small"><Text size="small">priorité </Text><Descending size="small"/></Box>, value: 'importance-descending' },
                  { label: <Box size="xxsmall" direction="row" align="center" pad="small" gap="small"><Text size="small">nom</Text><Ascending size="small"/></Box>, value: 'name-ascending' }
                ]}
                labelKey="label"
                valueKey="value"
                placeholder={<Box alignSelf="end"><Text alignSelf="end" size="small">Trier</Text></Box>}
                size="small"
                plain
                dropProps={{
                  border: false,
                  width: "small"
                }}
                textAlign="end"
              />
          </Box>
        )}
          <Grid fill="horizontal" columns={{ size: "medium" }} gap="small">
            {wishArray}
          </Grid>
          {wishArray.length === 0 && !isLoading && <Placeholder />}
        </PageContent>
      </>
      }
    </Page>
  );
};

export default Whishlist;
