import React from 'react'
import User from './User'
import {Layer} from 'grommet';
import { Box, Button, Card, CardBody, CardHeader, Heading, Text, TextInput} from 'grommet';
import { UserAdd } from 'grommet-icons';
import JoinFamilyForm from './JoinFamilyForm';
import CreateFamilyForm from './CreateFamilyForm';

class Family extends React.Component{
    constructor(props){
        super()
        this.state={
            users:props.users,
            familyName:props.name,
            familyCode:props.code,
            showFamilyJoinForm:false,
            showFamilyCode:false,
            showFamilyCreation:false
        }
        this.nav=props.nav
        this.toggleFamilyCode=this.toggleFamilyCode.bind(this)
        this.sendEmail=this.sendEmail.bind(this)
    }

    toggleFamilyCode(){
        this.setState({showFamilyCode:!this.state.showFamilyCode});
    }

    sendEmail(){
        const email = document.getElementById('text-input-pass').value;
        const { familyName, familyCode } = this.state;
        const username = this.props.username;
    
        fetch(`/api/register/invite/${familyCode}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, username, familyName })
        })
        .then(response => response.json())
        .then(data => {
            console.log('Email sent successfully:', data);
        })
        .catch(error => {
            console.error('Error sending email:', error);
        });
    }

    render(){
        const userarray = this.state.users
        .sort((item1,item2) => {
            if(item1.name.toLowerCase() < item2.name.toLowerCase()) return -1;
            if(item1.name.toLowerCase() > item2.name.toLowerCase()) return 1;
        })
        .map(item => <User key={item.uid} name={item.name} id={item.uid} status={item.status} img={item.img}/>)
        
        return(
            <Box>
                <Card round="xsmall">
                    <CardHeader pad="small">
                        <Heading size="small" margin="small">{this.state.familyName}</Heading>
                        <Box>
                            <Button secondary
                                onClick={this.toggleFamilyCode}
                                icon={<UserAdd/>}
                                label={"Inviter des participants"}
                                size="small"
                            />
                        </Box>
                    </CardHeader>
                    <CardBody pad="small">
                        <Box align="center" gap="medium" pad="small" direction='row' wrap={true}>{userarray}</Box>
                        <Box>
                            {(userarray.length===0) &&
                            <Text>
                                Oh! Il n'y a personne ici <span role="img" aria-label="triste">😿</span>
                            </Text>
                            }
                        </Box>
                        {this.state.showFamilyJoinForm && 
                            <JoinFamilyForm open={this.state.showFamilyJoinForm} toggle={this.toggleJoinFamilyForm} joinFamily={this.joinFamily}></JoinFamilyForm>
                        }
                        {this.state.showFamilyCreation && 
                            <CreateFamilyForm createFamily={this.createFamily} open={this.state.showFamilyCreation} toggle={this.toggleFamilyCreation}></CreateFamilyForm>
                        }
                        {this.state.showFamilyCode && 
                            <Layer onEsc={this.toggleFamilyCode} onClickOutside={this.toggleFamilyCode}>
                                <Box pad="medium">
                                    <Heading level={3}>Inviter des participants</Heading>
                                    <Heading level={4}>Les informations à communiquer</Heading>
                                    <TextInput
                                        placeholder="paul.dupont@gmail.fr"
                                        id="text-input-pass"
                                        name="emailInvit"
                                        type="email"
                                    />
                                    <Button label="Envoyer l'invitation" onClick={this.sendEmail}/>
                                    <Text>Pour ajouter quelqu'un à votre cercle familial, transmettez lui les informations suivantes :</Text>
                                    <Text>Nom de famille : <b>{this.state.familyName}</b></Text>
                                    <Text>Code de famille : <b>{this.state.familyCode}</b></Text>
                                    <Box direction="row" justify="end" margin={{ top: 'small' }}>
                                        <Button label="Fermer" onClick={this.toggleFamilyCode} />
                                    </Box>
                                </Box>
                            </Layer>
                        }
                    </CardBody>
                    
                </Card>
                    
            </Box>
            
        )
    };
}

export default Family