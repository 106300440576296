import React, { useState, useEffect } from 'react'
import { Box, Button, Form, FormField, Heading, TextInput, Layer, Text } from 'grommet';

const CreateFamilyForm = (props) => {
    // State initialization using useState
    const [fields, setFields] = useState({
        familycode: makeid(6),
        familyname: ""
    });
    const [errors, setErrors] = useState({});

    // Function to generate random family code
    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const validateFamilyName = () => {
        if (!fields.familyname) {
            return { "message": "Veuillez renseigner un nom de famille", status: "error" };
        }
        if (fields.familyname.length > 255) {
            return { "message": "Le nom de famille n'est pas valide", status: "error" };
        }
        return;
    }

    const handleValidation = () => {
        let formIsValid = true;
        let tempErrors = {};

        if (validateFamilyName()?.status === "error") {
            formIsValid = false;
            tempErrors["familyname"] = validateFamilyName().message;
        }

        setErrors(tempErrors);
        return formIsValid;
    };

    // Input change handler
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFields(prevFields => ({
            ...prevFields,
            [name]: value
        }));
    }

    // Form submission handler
    const postFamilyForm = () => {
        if (handleValidation()) {
            props.createFamily(fields.familycode, fields.familyname);
            props.toggle(); 
        }
    }

    return (
        <Layer position="center" onClickOutside={props.toggle} onEsc={props.toggle}>
            <Box pad="medium" gap="small">
                <Heading level={3}>Créer une nouvelle famille</Heading>
                <Form>
                    <FormField label="Nom de la famille" error={errors["familyname"]}>
                        <TextInput name="familyname" placeholder="Nom de la famille" value={fields.familyname} onChange={handleInputChange} />
                    </FormField>
                    <FormField label="Code d'invitation">
                        <TextInput disabled={true} name="familycode" placeholder="code" value={fields.familycode} />
                    </FormField>
                    <Box>
                        <Text>Attention <span role="img" aria-label="attention">💡</span></Text>
                        <Text>
                            Notez bien le code ci-dessus, il est nécessaire pour inviter des participants dans la famille.
                        </Text>
                    </Box>
                    <Box direction="row" justify="end" margin={{ top: 'small' }}>
                        <Button label="Annuler" onClick={props.toggle} />
                        <Button primary label="Valider" onClick={postFamilyForm} />
                    </Box>
                </Form>
            </Box>
        </Layer>
    );
}

export default CreateFamilyForm;